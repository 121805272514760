var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/admin/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('dahsboard')))])])])]}}])}),(_vm.type === 'admin' || _vm.type === 'agent')?_c('router-link',{attrs:{"to":"/admin/queues"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon far fa-clock"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('Queues')))])])])]}}],null,false,3787224360)}):_vm._e(),(_vm.type === 'admin'  || _vm.type === 'agent')?_c('router-link',{attrs:{"to":"/admin/clients"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-utensils"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('Clients')))])])])]}}],null,false,2147111974)}):_vm._e(),(_vm.type === 'admin' || _vm.type === 'finance'  || _vm.type === 'agent')?_c('router-link',{attrs:{"to":"/admin/drivers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-car"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('Drivers')))])])])]}}],null,false,2098669150)}):_vm._e(),_c('router-link',{attrs:{"to":"/admin/orders"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-shopping-cart"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('Orders')))])])])]}}])}),(_vm.type === 'admin' || _vm.type === 'agent')?_c('router-link',{attrs:{"to":"/admin/zones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-map-marked-alt"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('Zones')))])])])]}}],null,false,1878899647)}):_vm._e(),(_vm.type === 'admin' || _vm.type === 'agent')?_c('router-link',{attrs:{"to":"/admin/customers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-users"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('Customers')))])])])]}}],null,false,4150941654)}):_vm._e(),(_vm.type === 'admin' || _vm.type === 'agent')?_c('router-link',{attrs:{"to":"/admin/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-user"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('Users')))])])])]}}],null,false,2407420304)}):_vm._e(),(_vm.type === 'admin' || _vm.type === 'agent')?_c('router-link',{attrs:{"to":"/admin/schedule-manager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-calendar-alt"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('ScheduleManager')))])])])]}}],null,false,3202124243)}):_vm._e(),(_vm.type === 'admin' || _vm.type === 'agent')?_c('router-link',{attrs:{"to":"/admin/clients-tarrif"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-clipboard-list"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('ClientsTarrif')))])])])]}}],null,false,1912122448)}):_vm._e(),(_vm.type === 'admin' || _vm.type === 'agent' || _vm.type === 'finance')?_c('router-link',{attrs:{"to":"/admin/pending-transactions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-user-clock"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('PendingTransactions')))])])])]}}],null,false,2755253951)}):_vm._e(),(_vm.type === 'admin' || _vm.type === 'agent' || _vm.type === 'finance')?_c('router-link',{attrs:{"to":"/admin/all-transactions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-check-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('AllTransactions')))])])])]}}],null,false,4175365324)}):_vm._e(),(_vm.type === 'admin' || _vm.type === 'agent')?_c('router-link',{attrs:{"to":"/admin/general-settings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-sliders-h"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t('General Settings')))])])])]}}],null,false,1986040323)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }