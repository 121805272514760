<template>
  <ul class="menu-nav">
    <router-link
      to="/admin/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">{{$t('dahsboard')}}</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/admin/queues"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="type === 'admin' || type === 'agent'"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-clock"></i>
          <span class="menu-text">{{$t('Queues')}}</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/admin/clients"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="type === 'admin'  || type === 'agent'"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-utensils"></i>
          <span class="menu-text">{{$t('Clients')}}</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/admin/drivers"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="type === 'admin' || type === 'finance'  || type === 'agent'"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-car"></i>

          <span class="menu-text">{{$t('Drivers')}}</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/admin/orders"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-shopping-cart"></i>

          <span class="menu-text">{{$t('Orders')}}</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/admin/zones"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="type === 'admin' || type === 'agent'"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-map-marked-alt"></i>

          <span class="menu-text">{{$t('Zones')}}</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/admin/customers"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="type === 'admin' || type === 'agent'"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users"></i>

          <span class="menu-text">{{$t('Customers')}}</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/admin/users"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="type === 'admin' || type === 'agent'"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-user"></i>

          <span class="menu-text">{{$t('Users')}}</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/admin/schedule-manager"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="type === 'admin' || type === 'agent'"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-calendar-alt"></i>

          <span class="menu-text">{{$t('ScheduleManager')}}</span>
        </a>
      </li>
    </router-link>


     <router-link
      to="/admin/clients-tarrif"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="type === 'admin' || type === 'agent'"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-clipboard-list"></i>

          <span class="menu-text">{{$t('ClientsTarrif')}}</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/admin/pending-transactions"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="type === 'admin' || type === 'agent' || type === 'finance'"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-user-clock"></i>

          <span class="menu-text">{{$t('PendingTransactions')}}</span>
        </a>
      </li>
    </router-link>


    <router-link
      to="/admin/all-transactions"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="type === 'admin' || type === 'agent' || type === 'finance'"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-check-circle"></i>

          <span class="menu-text">{{$t('AllTransactions')}}</span>
        </a>
      </li>
    </router-link>


    <router-link
      to="/admin/general-settings"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="type === 'admin' || type === 'agent'"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-sliders-h"></i>

          <span class="menu-text">{{$t('General Settings')}}</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
export default {
  name: "KTMenu",
  data(){
    return{
      type:''
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  },
  mounted(){
this.$store.dispatch(VERIFY_AUTH).then(res =>{
      var data = res.data[0]
      //console.log(data.type)
      this.type = data.type
      })
  }
};
</script>
